import React from "react";
import TextPage from "../components/TextPage";

function DatenschutzPage(){
    return (
        <TextPage heading="Datenschutz">
            <div className="card">
            <div style={{maxHeight: 700, overflowY: "scroll"}}>
                <h5 className="b3">Datenschutzerklärung</h5>
                <div>
                    <p>
                        Eine Nutzung der Internetseiten der Legal Software UG
                        (haftungsbeschränkt) ist grundsätzlich ohne jede Angabe
                        personenbezogener Daten möglich. Sofern eine betroffene Person
                        besondere Services unseres Unternehmens über unsere Internetseite in
                        Anspruch nehmen möchte, könnte jedoch eine Verarbeitung
                        personenbezogener Daten erforderlich werden. Ist die Verarbeitung
                        personenbezogener Daten erforderlich und besteht für eine solche
                        Verarbeitung keine gesetzliche Grundlage, holen wir generell eine
                        Einwilligung der betroffenen Person ein.
                    </p>
                </div>
                <div>
                    <p>
                        Die Verarbeitung personenbezogener Daten, beispielsweise des Namens,
                        der Anschrift, E-Mail-Adresse oder Telefonnummer einer betroffenen
                        Person, erfolgt stets im Einklang mit der Datenschutz-Grundverordnung
                        und in Übereinstimmung mit den für die Legal Software UG
                        (haftungsbeschränkt) geltenden landesspezifischen
                        Datenschutzbestimmungen. Mittels dieser Datenschutzerklärung möchte
                        unser Unternehmen die Öffentlichkeit über Art, Umfang und Zweck der von
                        uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten
                        informieren. Ferner werden betroffene Personen mittels dieser
                        Datenschutzerklärung über die ihnen zustehenden Rechte aufgeklärt.
                    </p>
                </div>
                <div>
                    <p>
                        Die Legal Software UG (haftungsbeschränkt) hat als für die Verarbeitung
                        Verantwortlicher zahlreiche technische und organisatorische Maßnahmen
                        umgesetzt, um einen möglichst lückenlosen Schutz der über diese
                        Internetseite verarbeiteten personenbezogenen Daten sicherzustellen.
                        Dennoch können Internetbasierte Datenübertragungen grundsätzlich
                        Sicherheitslücken aufweisen, sodass ein absoluter Schutz nicht
                        gewährleistet werden kann. Aus diesem Grund steht es jeder betroffenen
                        Person frei, personenbezogene Daten auch auf alternativen Wegen,
                        beispielsweise telefonisch, an uns zu übermitteln.
                    </p>
                </div>
                <div>
                    <p>
                        1. Begriffsbestimmungen
                    </p>
                </div>
                <div>
                    <div>
                        <p>
                            Die Datenschutzerklärung der Legal Software UG (haftungsbeschränkt)
                            beruht auf den Begrifflichkeiten, die durch den Europäischen
                            Richtlinien- und Verordnungsgeber beim Erlass der
                            Datenschutz-Grundverordnung (DS-GVO) verwendet wurden. Unsere
                            Datenschutzerklärung soll sowohl für die Öffentlichkeit als auch
                            für unsere Kunden und Geschäftspartner einfach lesbar und
                            verständlich sein. Um dies zu gewährleisten, möchten wir vorab die
                            verwendeten Begrifflichkeiten erläutern.
                        </p>
                    </div>
                    <div>
                        <p>
                            Wir verwenden in dieser Datenschutzerklärung unter anderem die
                            folgenden Begriffe:
                        </p>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    a)    personenbezogene Daten
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Personenbezogene Daten sind alle Informationen, die sich auf eine
                            identifizierte oder identifizierbare natürliche Person (im
                            Folgenden „betroffene Person“) beziehen. Als identifizierbar wird
                            eine natürliche Person angesehen, die direkt oder indirekt,
                            insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu
                            einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu
                            einem oder mehreren besonderen Merkmalen, die Ausdruck der
                            physischen, physiologischen, genetischen, psychischen,
                            wirtschaftlichen, kulturellen oder sozialen Identität dieser
                            natürlichen Person sind, identifiziert werden kann.
                        </p>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    b)    betroffene Person
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div>
                        <p>
                            Betroffene Person ist jede identifizierte oder identifizierbare
                            natürliche Person, deren personenbezogene Daten von dem für die
                            Verarbeitung Verantwortlichen verarbeitet werden.
                        </p>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    c)    Verarbeitung
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Verarbeitung ist jeder mit oder ohne Hilfe automatisierter
                            Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im
                            Zusammenhang mit personenbezogenen Daten wie das Erheben, das
                            Erfassen, die Organisation, das Ordnen, die Speicherung, die
                            Anpassung oder Veränderung, das Auslesen, das Abfragen, die
                            Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder
                            eine andere Form der Bereitstellung, den Abgleich oder die
                            Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.
                        </p>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    d)    Einschränkung der Verarbeitung
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Einschränkung der Verarbeitung ist die Markierung gespeicherter
                            personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung
                            einzuschränken.
                        </p>
                    </div>
                </div>
                <div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    e)    Profiling
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Profiling ist jede Art der automatisierten Verarbeitung
                            personenbezogener Daten, die darin besteht, dass diese
                            personenbezogenen Daten verwendet werden, um bestimmte persönliche
                            Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten,
                            insbesondere, um Aspekte bezüglich Arbeitsleistung,
                            wirtschaftlicher Lage, Gesundheit, persönlicher Vorlieben,
                            Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder
                            Ortswechsel dieser natürlichen Person zu analysieren oder
                            vorherzusagen.
                        </p>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    f)     Pseudonymisierung
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Pseudonymisierung ist die Verarbeitung personenbezogener Daten in
                            einer Weise, auf welche die personenbezogenen Daten ohne
                            Hinzuziehung zusätzlicher Informationen nicht mehr einer
                            spezifischen betroffenen Person zugeordnet werden können, sofern
                            diese zusätzlichen Informationen gesondert aufbewahrt werden und
                            technischen und organisatorischen Maßnahmen unterliegen, die
                            gewährleisten, dass die personenbezogenen Daten nicht einer
                            identifizierten oder identifizierbaren natürlichen Person
                            zugewiesen werden.
                        </p>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    g)    Verantwortlicher oder für die Verarbeitung
                                    Verantwortlicher
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div>
                        <p>
                            Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die
                            natürliche oder juristische Person, Behörde, Einrichtung oder
                            andere Stelle, die allein oder gemeinsam mit anderen über die
                            Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
                            entscheidet. Sind die Zwecke und Mittel dieser Verarbeitung durch
                            das Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so
                            kann der Verantwortliche beziehungsweise können die bestimmten
                            Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der
                            Mitgliedstaaten vorgesehen werden.
                        </p>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    h)    Auftragsverarbeiter
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Auftragsverarbeiter ist eine natürliche oder juristische Person,
                            Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten
                            im Auftrag des Verantwortlichen verarbeitet.
                        </p>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    i)      Empfänger
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Empfänger ist eine natürliche oder juristische Person, Behörde,
                            Einrichtung oder andere Stelle, der personenbezogene Daten
                            offengelegt werden, unabhängig davon, ob es sich bei ihr um einen
                            Dritten handelt oder nicht. Behörden, die im Rahmen eines
                            bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem
                            Recht der Mitgliedstaaten möglicherweise personenbezogene Daten
                            erhalten, gelten jedoch nicht als Empfänger.
                        </p>
                    </div>
                </div>
                <div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    j)      Dritter
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Dritter ist eine natürliche oder juristische Person, Behörde,
                            Einrichtung oder andere Stelle außer der betroffenen Person, dem
                            Verantwortlichen, dem Auftragsverarbeiter und den Personen, die
                            unter der unmittelbaren Verantwortung des Verantwortlichen oder des
                            Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu
                            verarbeiten.
                        </p>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    k)    Einwilligung
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Einwilligung ist jede von der betroffenen Person freiwillig für den
                            bestimmten Fall in informierter Weise und unmissverständlich
                            abgegebene Willensbekundung in Form einer Erklärung oder einer
                            sonstigen eindeutigen bestätigenden Handlung, mit der die
                            betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung
                            der sie betreffenden personenbezogenen Daten einverstanden ist.
                        </p>
                    </div>
                    <div>
                        <p>
                            2. Name und Anschrift des für die Verarbeitung Verantwortlichen
                        </p>
                    </div>
                </div>
                <div>
                    <p>
                        Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in
                        den Mitgliedstaaten der Europäischen Union geltenden Datenschutzgesetze
                        und anderer Bestimmungen mit datenschutzrechtlichem Charakter ist die:
                    </p>
                </div>
                <div>
                    <p>
                        Legal Software UG (haftungsbeschränkt)
                    </p>
                </div>
                <div>
                    <p>
                        Feilitzschstraße 7
                    </p>
                </div>
                <div>
                    <p>
                        80802 München
                    </p>
                </div>
                <div>
                    <p>
                        Deutschland
                    </p>
                </div>
                <div>
                    <p>
                        Tel.: 089/22061333
                    </p>
                </div>
                <div>
                    <p>
                        E-Mail: info@legal-software.io
                    </p>
                </div>
                <div>
                    <p>
                        Website:
                        <a
                            href="http://www.legal-software.io/"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            www.legal-software.io
                        </a>
                    </p>
                </div>
                <div>
                    <p>
                        3. Cookies
                    </p>
                </div>
                <div>
                    <p>
                        Die Internetseiten der Legal Software UG (haftungsbeschränkt) verwenden
                        Cookies. Cookies sind Textdateien, welche über einen Internetbrowser
                        auf einem Computersystem abgelegt und gespeichert werden.
                    </p>
                </div>
                <div>
                </div>
                <div>
                    <p>
                        Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies
                        enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist eine eindeutige
                        Kennung des Cookies. Sie besteht aus einer Zeichenfolge, durch welche
                        Internetseiten und Server dem konkreten Internetbrowser zugeordnet
                        werden können, in dem das Cookie gespeichert wurde. Dies ermöglicht es
                        den besuchten Internetseiten und Servern, den individuellen Browser der
                        betroffenen Person von anderen Internetbrowsern, die andere Cookies
                        enthalten, zu unterscheiden. Ein bestimmter Internetbrowser kann über
                        die eindeutige Cookie-ID wiedererkannt und identifiziert werden.
                    </p>
                </div>
                <div>
                </div>
                <div>
                    <p>
                        Durch den Einsatz von Cookies kann die Legal Software UG
                        (haftungsbeschränkt) den Nutzern dieser Internetseite
                        nutzerfreundlichere Services bereitstellen, die ohne die Cookie-Setzung
                        nicht möglich wären.
                    </p>
                </div>
                <div>
                </div>
                <div>
                    <p>
                        Mittels eines Cookies können die Informationen und Angebote auf unserer
                        Internetseite im Sinne des Benutzers optimiert werden. Cookies
                        ermöglichen uns, wie bereits erwähnt, die Benutzer unserer
                        Internetseite wiederzuerkennen. Zweck dieser Wiedererkennung ist es,
                        den Nutzern die Verwendung unserer Internetseite zu erleichtern. Der
                        Benutzer einer Internetseite, die Cookies verwendet, muss
                        beispielsweise nicht bei jedem Besuch der Internetseite erneut seine
                        Zugangsdaten eingeben, weil dies von der Internetseite und dem auf dem
                        Computersystem des Benutzers abgelegten Cookie übernommen wird. Ein
                        weiteres Beispiel ist das Cookie eines Warenkorbes im Online-Shop. Der
                        Online-Shop merkt sich die Artikel, die ein Kunde in den virtuellen
                        Warenkorb gelegt hat, über ein Cookie.
                    </p>
                </div>
                <div>
                </div>
                <div>
                    <p>
                        Die betroffene Person kann die Setzung von Cookies durch unsere
                        Internetseite jederzeit mittels einer entsprechenden Einstellung des
                        genutzten Internetbrowsers verhindern und damit der Setzung von Cookies
                        dauerhaft widersprechen. Ferner können bereits gesetzte Cookies
                        jederzeit über einen Internetbrowser oder andere Softwareprogramme
                        gelöscht werden. Dies ist in allen gängigen Internetbrowsern möglich.
                        Deaktiviert die betroffene Person die Setzung von Cookies in dem
                        genutzten Internetbrowser, sind unter Umständen nicht alle Funktionen
                        unserer Internetseite vollumfänglich nutzbar.
                    </p>
                </div>
                <div>
                    <p>
                        4. Erfassung von allgemeinen Daten und Informationen
                    </p>
                </div>
                <div>
                    <p>
                        Die Internetseite der Legal Software UG (haftungsbeschränkt) erfasst
                        mit jedem Aufruf der Internetseite durch eine betroffene Person oder
                        ein automatisiertes System eine Reihe von allgemeinen Daten und
                        Informationen. Diese allgemeinen Daten und Informationen werden in den
                        Logfiles des Servers gespeichert. Erfasst werden können die (1)
                        verwendeten Browsertypen und Versionen, (2) das vom zugreifenden System
                        verwendete Betriebssystem, (3) die Internetseite, von welcher ein
                        zugreifendes System auf unsere Internetseite gelangt (sogenannte
                        Referrer), (4) die Unterwebseiten, welche über ein zugreifendes System
                        auf unserer Internetseite angesteuert werden, (5) das Datum und die
                        Uhrzeit eines Zugriffs auf die Internetseite, (6) eine
                        Internet-Protokoll-Adresse (IP-Adresse), (7) der
                        Internet-Service-Provider des zugreifenden Systems und (8) sonstige
                        ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle von
                        Angriffen auf unsere informationstechnologischen Systeme dienen.
                    </p>
                </div>
                <div>
                    <p>
                        Bei der Nutzung dieser allgemeinen Daten und Informationen zieht die
                        Legal Software UG (haftungsbeschränkt) keine Rückschlüsse auf die
                        betroffene Person. Diese Informationen werden vielmehr benötigt, um (1)
                        die Inhalte unserer Internetseite korrekt auszuliefern, (2) die Inhalte
                        unserer Internetseite sowie die Werbung für diese zu optimieren, (3)
                        die dauerhafte Funktionsfähigkeit unserer informationstechnologischen
                        Systeme und der Technik unserer Internetseite zu gewährleisten sowie
                        (4) um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur
                        Strafverfolgung notwendigen Informationen bereitzustellen. Diese anonym
                        erhobenen Daten und Informationen werden durch die Legal Software UG
                        (haftungsbeschränkt) daher einerseits statistisch und ferner mit dem
                        Ziel ausgewertet, den Datenschutz und die Datensicherheit in unserem
                        Unternehmen zu erhöhen, um letztlich ein optimales Schutzniveau für die
                        von uns verarbeiteten personenbezogenen Daten sicherzustellen. Die
                        anonymen Daten der Server-Logfiles werden getrennt von allen durch eine
                        betroffene Person angegebenen personenbezogenen Daten gespeichert.
                    </p>
                </div>
                <div>
                    <p>
                        5. Registrierung auf unserer Internetseite
                    </p>
                </div>
                <div>
                    <p>
                        Die betroffene Person hat die Möglichkeit, sich auf der Internetseite
                        des für die Verarbeitung Verantwortlichen unter Angabe von
                        personenbezogenen Daten zu registrieren. Welche personenbezogenen Daten
                        dabei an den für die Verarbeitung Verantwortlichen übermittelt werden,
                        ergibt sich aus der jeweiligen Eingabemaske, die für die Registrierung
                        verwendet wird. Die von der betroffenen Person eingegebenen
                        personenbezogenen Daten werden ausschließlich für die interne
                        Verwendung bei dem für die Verarbeitung Verantwortlichen und für eigene
                        Zwecke erhoben und gespeichert. Der für die Verarbeitung
                        Verantwortliche kann die Weitergabe an einen oder mehrere
                        Auftragsverarbeiter, beispielsweise einen Paketdienstleister,
                        veranlassen, der die personenbezogenen Daten ebenfalls ausschließlich
                        für eine interne Verwendung, die dem für die Verarbeitung
                        Verantwortlichen zuzurechnen ist, nutzt.
                    </p>
                </div>
                <div>
                    <p>
                        Durch eine Registrierung auf der Internetseite des für die Verarbeitung
                        Verantwortlichen wird ferner die vom Internet-Service-Provider (ISP)
                        der betroffenen Person vergebene IP-Adresse, das Datum sowie die
                        Uhrzeit der Registrierung gespeichert. Die Speicherung dieser Daten
                        erfolgt vor dem Hintergrund, dass nur so der Missbrauch unserer Dienste
                        verhindert werden kann, und diese Daten im Bedarfsfall ermöglichen,
                        begangene Straftaten aufzuklären. Insofern ist die Speicherung dieser
                        Daten zur Absicherung des für die Verarbeitung Verantwortlichen
                        erforderlich. Eine Weitergabe dieser Daten an Dritte erfolgt
                        grundsätzlich nicht, sofern keine gesetzliche Pflicht zur Weitergabe
                        besteht oder die Weitergabe der Strafverfolgung dient.
                    </p>
                </div>
                <div>
                    <p>
                        Die Registrierung der betroffenen Person unter freiwilliger Angabe
                        personenbezogener Daten dient dem für die Verarbeitung Verantwortlichen
                        dazu, der betroffenen Person Inhalte oder Leistungen anzubieten, die
                        aufgrund der Natur der Sache nur registrierten Benutzern angeboten
                        werden können. Registrierten Personen steht die Möglichkeit frei, die
                        bei der Registrierung angegebenen personenbezogenen Daten jederzeit
                        abzuändern oder vollständig aus dem Datenbestand des für die
                        Verarbeitung Verantwortlichen löschen zu lassen.
                    </p>
                </div>
                <div>
                    <p>
                        Der für die Verarbeitung Verantwortliche erteilt jeder betroffenen
                        Person jederzeit auf Anfrage Auskunft darüber, welche personenbezogenen
                        Daten über die betroffene Person gespeichert sind. Ferner berichtigt
                        oder löscht der für die Verarbeitung Verantwortliche personenbezogene
                        Daten auf Wunsch oder Hinweis der betroffenen Person, soweit dem keine
                        gesetzlichen Aufbewahrungspflichten entgegenstehen. Die Gesamtheit der
                        Mitarbeiter des für die Verarbeitung Verantwortlichen stehen der
                        betroffenen Person in diesem Zusammenhang als Ansprechpartner zur
                        Verfügung.
                    </p>
                </div>
                <div>
                    <p>
                        6. Abonnement unseres Newsletters
                    </p>
                </div>
                <div>
                    <p>
                        Auf der Internetseite der Legal Software UG (haftungsbeschränkt) wird
                        den Benutzern die Möglichkeit eingeräumt, den Newsletter unseres
                        Unternehmens zu abonnieren. Welche personenbezogenen Daten bei der
                        Bestellung des Newsletters an den für die Verarbeitung Verantwortlichen
                        übermittelt werden, ergibt sich aus der hierzu verwendeten
                        Eingabemaske.
                    </p>
                </div>
                <div>
                    <p>
                        Die Legal Software UG (haftungsbeschränkt) informiert ihre Kunden und
                        Geschäftspartner in regelmäßigen Abständen im Wege eines Newsletters
                        über Angebote des Unternehmens. Der Newsletter unseres Unternehmens
                        kann von der betroffenen Person grundsätzlich nur dann empfangen
                        werden, wenn (1) die betroffene Person über eine gültige E-Mail-Adresse
                        verfügt und (2) die betroffene Person sich für den Newsletterversand
                        registriert. An die von einer betroffenen Person erstmalig für den
                        Newsletterversand eingetragene E-Mail-Adresse wird aus rechtlichen
                        Gründen eine Bestätigungsmail im Double-Opt-In-Verfahren versendet.
                        Diese Bestätigungsmail dient der Überprüfung, ob der Inhaber der
                        E-Mail-Adresse als betroffene Person den Empfang des Newsletters
                        autorisiert hat.
                    </p>
                </div>
                <div>
                    <p>
                        Bei der Anmeldung zum Newsletter speichern wir ferner die vom
                        Internet-Service-Provider (ISP) vergebene IP-Adresse des von der
                        betroffenen Person zum Zeitpunkt der Anmeldung verwendeten
                        Computersystems sowie das Datum und die Uhrzeit der Anmeldung. Die
                        Erhebung dieser Daten ist erforderlich, um den(möglichen) Missbrauch
                        der E-Mail-Adresse einer betroffenen Person zu einem späteren Zeitpunkt
                        nachvollziehen zu können und dient deshalb der rechtlichen Absicherung
                        des für die Verarbeitung Verantwortlichen.
                    </p>
                </div>
                <div>
                    <p>
                        Die im Rahmen einer Anmeldung zum Newsletter erhobenen
                        personenbezogenen Daten werden ausschließlich zum Versand unseres
                        Newsletters verwendet. Ferner könnten Abonnenten des Newsletters per
                        E-Mail informiert werden, sofern dies für den Betrieb des
                        Newsletter-Dienstes oder eine diesbezügliche Registrierung erforderlich
                        ist, wie dies im Falle von Änderungen am Newsletterangebot oder bei der
                        Veränderung der technischen Gegebenheiten der Fall sein könnte. Es
                        erfolgt keine Weitergabe der im Rahmen des Newsletter-Dienstes
                        erhobenen personenbezogenen Daten an Dritte. Das Abonnement unseres
                        Newsletters kann durch die betroffene Person jederzeit gekündigt
                        werden. Die Einwilligung in die Speicherung personenbezogener Daten,
                        die die betroffene Person uns für den Newsletterversand erteilt hat,
                        kann jederzeit widerrufen werden. Zum Zwecke des Widerrufs der
                        Einwilligung findet sich in jedem Newsletter ein entsprechender Link.
                        Ferner besteht die Möglichkeit, sich jederzeit auch direkt auf der
                        Internetseite des für die Verarbeitung Verantwortlichen vom
                        Newsletterversand abzumelden oder dies dem für die Verarbeitung
                        Verantwortlichen auf andere Weise mitzuteilen.
                    </p>
                </div>
                <div>
                    <p>
                        7. Newsletter-Tracking
                    </p>
                </div>
                <div>
                    <p>
                        Die Newsletter der Legal Software UG (haftungsbeschränkt) enthalten
                        sogenannte Zählpixel. Ein Zählpixel ist eine Miniaturgrafik, die in
                        solche E-Mails eingebettet wird, welche im HTML-Format versendet
                        werden, um eine Logdatei-Aufzeichnung und eine Logdatei-Analyse zu
                        ermöglichen. Dadurch kann eine statistische Auswertung des Erfolges
                        oder Misserfolges von Online-Marketing-Kampagnen durchgeführt werden.
                        Anhand des eingebetteten Zählpixels kann die Legal Software UG
                        (haftungsbeschränkt) erkennen, ob und wann eine E-Mail von einer
                        betroffenen Person geöffnet wurde und welche in der E-Mail befindlichen
                        Links von der betroffenen Person aufgerufen wurden.
                    </p>
                </div>
                <div>
                    <p>
                        Solche über die in den Newslettern enthaltenen Zählpixel erhobenen
                        personenbezogenen Daten, werden von dem für die Verarbeitung
                        Verantwortlichen gespeichert und ausgewertet, um den Newsletterversand
                        zu optimieren und den Inhalt zukünftiger Newsletter noch besser den
                        Interessen der betroffenen Person anzupassen. Diese personenbezogenen
                        Daten werden nicht an Dritte weitergegeben. Betroffene Personen sind
                        jederzeit berechtigt, die diesbezügliche gesonderte, über das
                        Double-Opt-In-Verfahren abgegebene Einwilligungserklärung zu
                        widerrufen. Nach einem Widerruf werden diese personenbezogenen Daten
                        von dem für die Verarbeitung Verantwortlichen gelöscht. Eine Abmeldung
                        vom Erhalt des Newsletters deutet die Legal Software UG
                        (haftungsbeschränkt) automatisch als Widerruf.
                    </p>
                </div>
                <div>
                    <p>
                        8. Kontaktmöglichkeit über die Internetseite
                    </p>
                </div>
                <div>
                    <p>
                        Die Internetseite der Legal Software UG (haftungsbeschränkt) enthält
                        aufgrund von gesetzlichen Vorschriften Angaben, die eine schnelle
                        elektronische Kontaktaufnahme zu unserem Unternehmen sowie eine
                        unmittelbare Kommunikation mit uns ermöglichen, was ebenfalls eine
                        allgemeine Adresse der sogenannten elektronischen Post (E-Mail-Adresse)
                        umfasst. Sofern eine betroffene Person per E-Mail oder über ein
                        Kontaktformular den Kontakt mit dem für die Verarbeitung
                        Verantwortlichen aufnimmt, werden die von der betroffenen Person
                        übermittelten personenbezogenen Daten automatisch gespeichert. Solche
                        auf freiwilliger Basis von einer betroffenen Person an den für die
                        Verarbeitung Verantwortlichen übermittelten personenbezogenen Daten
                        werden für Zwecke der Bearbeitung oder der Kontaktaufnahme zur
                        betroffenen Person gespeichert. Es erfolgt keine Weitergabe dieser
                        personenbezogenen Daten an Dritte.
                    </p>
                </div>
                <div>
                    <p>
                        9. Abonnement von Kommentaren im Blog auf der Internetseite
                    </p>
                </div>
                <div>
                    <p>
                        Die im Blog der Legal Software UG (haftungsbeschränkt) abgegebenen
                        Kommentare können grundsätzlich von Dritten abonniert werden.
                        Insbesondere besteht die Möglichkeit, dass ein Kommentator die seinem
                        Kommentar nachfolgenden Kommentare zu einem bestimmten Blog-Beitrag
                        abonniert.
                    </p>
                </div>
                <div>
                    <p>
                        Sofern sich eine betroffene Person für die Option entscheidet,
                        Kommentare zu abonnieren, versendet der für die Verarbeitung
                        Verantwortliche eine automatische Bestätigungsmail, um im
                        Double-Opt-In-Verfahren zu überprüfen, ob sich wirklich der Inhaber der
                        angegebenen E-Mail-Adresse für diese Option entschieden hat. Die Option
                        zum Abonnement von Kommentaren kann jederzeit beendet werden.
                    </p>
                </div>
                <div>
                    <p>
                        10. Routinemäßige Löschung und Sperrung von personenbezogenen Daten
                    </p>
                </div>
                <div>
                    <div>
                        <p>
                            Der für die Verarbeitung Verantwortliche verarbeitet und speichert
                            personenbezogene Daten der betroffenen Person nur für den Zeitraum,
                            der zur Erreichung des Speicherungszwecks erforderlich ist oder
                            sofern dies durch den Europäischen Richtlinien- und
                            Verordnungsgeber oder einen anderen Gesetzgeber in Gesetzen oder
                            Vorschriften, welchen der für die Verarbeitung Verantwortliche
                            unterliegt, vorgesehen wurde.
                        </p>
                    </div>
                    <div>
                        <p>
                            Entfällt der Speicherungszweck oder läuft eine vom Europäischen
                            Richtlinien- und Verordnungsgeber oder einem anderen zuständigen
                            Gesetzgeber vorgeschriebene Speicherfrist ab, werden die
                            personenbezogenen Daten routinemäßig und entsprechend den
                            gesetzlichen Vorschriften gesperrt oder gelöscht.
                        </p>
                    </div>
                    <div>
                        <p>
                            11. Rechte der betroffenen Person
                        </p>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    a)    Recht auf Bestätigung
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Jede betroffene Person hat das vom Europäischen Richtlinien- und
                            Verordnungsgeber eingeräumte Recht, von dem für die Verarbeitung
                            Verantwortlichen eine Bestätigung darüber zu verlangen, ob sie
                            betreffende personenbezogene Daten verarbeitet werden. Möchte eine
                            betroffene Person dieses Bestätigungsrecht in Anspruch nehmen, kann
                            sie sich hierzu jederzeit an einen Mitarbeiter des für die
                            Verarbeitung Verantwortlichen wenden.
                        </p>
                    </div>
                </div>
                <div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    b)    Recht auf Auskunft
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Jede von der Verarbeitung personenbezogener Daten betroffene Person
                            hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte
                            Recht, jederzeit von dem für die Verarbeitung Verantwortlichen
                            unentgeltliche Auskunft über die zu seiner Person gespeicherten
                            personenbezogenen Daten und eine Kopie dieser Auskunft zu erhalten.
                            Ferner hat der Europäische Richtlinien- und Verordnungsgeber der
                            betroffenen Person Auskunft über folgende Informationen
                            zugestanden:
                        </p>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    die Verarbeitungszwecke
                                </p>
                            </li>
                            <li>
                                <p>
                                    die Kategorien personenbezogener Daten, die verarbeitet
                                    werden
                                </p>
                            </li>
                            <li>
                                <p>
                                    die Empfänger oder Kategorien von Empfängern, gegenüber
                                    denen die personenbezogenen Daten offengelegt worden sind
                                    oder noch offengelegt werden, insbesondere bei Empfängern
                                    in Drittländern oder bei internationalen Organisationen
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    falls möglich die geplante Dauer, für die die
                                    personenbezogenen Daten gespeichert werden, oder, falls
                                    dies nicht möglich ist, die Kriterien für die Festlegung
                                    dieser Dauer
                                </p>
                            </li>
                            <li>
                                <p>
                                    das Bestehen eines Rechts auf Berichtigung oder Löschung
                                    der sie betreffenden personenbezogenen Daten oder auf
                                    Einschränkung der Verarbeitung durch den Verantwortlichen
                                    oder eines Widerspruchsrechts gegen diese Verarbeitung
                                </p>
                            </li>
                            <li>
                                <p>
                                    das Bestehen eines Beschwerderechts bei einer
                                    Aufsichtsbehörde
                                </p>
                            </li>
                            <li>
                                <p>
                                    wenn die personenbezogenen Daten nicht bei der betroffenen
                                    Person erhoben werden: Alle verfügbaren Informationen über
                                    die Herkunft der Daten
                                </p>
                            </li>
                            <li>
                                <p>
                                    das Bestehen einer automatisierten Entscheidungsfindung
                                    einschließlich Profiling gemäß Artikel 22 Abs.1 und 4
                                    DS-GVO und — zumindest in diesen Fällen — aussagekräftige
                                    Informationen über die involvierte Logik sowie die
                                    Tragweite und die angestrebten Auswirkungen einer
                                    derartigen Verarbeitung für die betroffene Person
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div>
                        <p>
                            Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu,
                            ob personenbezogene Daten an ein Drittland oder an eine
                            internationale Organisation übermittelt wurden. Sofern dies der
                            Fall ist, so steht der betroffenen Person im Übrigen das Recht zu,
                            Auskunft über die geeigneten Garantien im Zusammenhang mit der
                            Übermittlung zu erhalten.
                        </p>
                    </div>
                    <div>
                        <p>
                            Möchte eine betroffene Person dieses Auskunftsrecht in Anspruch
                            nehmen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für
                            die Verarbeitung Verantwortlichen wenden.
                        </p>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    c)    Recht auf Berichtigung
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Jede von der Verarbeitung personenbezogener Daten betroffene Person
                            hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte
                            Recht, die unverzügliche Berichtigung sie betreffender unrichtiger
                            personenbezogener Daten zu verlangen. Ferner steht der betroffenen
                            Person das Recht zu, unter Berücksichtigung der Zwecke der
                            Verarbeitung, die Vervollständigung unvollständiger
                            personenbezogener Daten — auch mittels einer ergänzenden Erklärung
                            — zu verlangen.
                        </p>
                    </div>
                    <div>
                        <p>
                            Möchte eine betroffene Person dieses Berichtigungsrecht in Anspruch
                            nehmen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für
                            die Verarbeitung Verantwortlichen wenden.
                        </p>
                    </div>
                </div>
                <div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    d)    Recht auf Löschung (Recht auf Vergessen werden)
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Jede von der Verarbeitung personenbezogener Daten betroffene Person
                            hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte
                            Recht, von dem Verantwortlichen zu verlangen, dass die sie
                            betreffenden personenbezogenen Daten unverzüglich gelöscht werden,
                            sofern einer der folgenden Gründe zutrifft und soweit die
                            Verarbeitung nicht erforderlich ist:
                        </p>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    Die personenbezogenen Daten wurden für solche Zwecke
                                    erhoben oder auf sonstige Weise verarbeitet, für welche sie
                                    nicht mehr notwendig sind.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Die betroffene Person widerruft ihre Einwilligung, auf die
                                    sich die Verarbeitung gemäß Art. 6 Abs. 1 Buchstabe a
                                    DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO stützte, und
                                    es fehlt an einer anderweitigen Rechtsgrundlage für die
                                    Verarbeitung.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Die betroffene Person legt gemäß Art. 21 Abs. 1 DS-GVO
                                    Widerspruch gegen die Verarbeitung ein, und es liegen keine
                                    vorrangigen berechtigten Gründe für die Verarbeitung vor,
                                    oder die betroffene Person legt gemäß Art. 21 Abs. 2 DS-GVO
                                    Widerspruch gegen die Verarbeitung ein.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    Die personenbezogenen Daten wurden unrechtmäßig
                                    verarbeitet.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Die Löschung der personenbezogenen Daten ist zur Erfüllung
                                    einer rechtlichen Verpflichtung nach dem Unionsrecht oder
                                    dem Recht der Mitgliedstaaten erforderlich, dem der
                                    Verantwortliche unterliegt.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Die personenbezogenen Daten wurden in Bezug auf angebotene
                                    Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1
                                    DS-GVO erhoben.
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Sofern einer der oben genannten Gründe zutrifft und eine betroffene
                            Person die Löschung von personenbezogenen Daten, die bei der Legal
                            Software UG (haftungsbeschränkt) gespeichert sind, veranlassen
                            möchte, kann sie sich hierzu jederzeit an einen Mitarbeiter des für
                            die Verarbeitung Verantwortlichen wenden. Der Mitarbeiter der Legal
                            Software UG (haftungsbeschränkt) wird veranlassen, dass dem
                            Löschverlangen unverzüglich nachgekommen wird.
                        </p>
                    </div>
                    <div>
                        <p>
                            Wurden die personenbezogenen Daten von der Legal Software UG
                            (haftungsbeschränkt) öffentlich gemacht und ist unser Unternehmen
                            als Verantwortlicher gemäß Art. 17 Abs. 1 DS-GVO zur Löschung der
                            personenbezogenen Daten verpflichtet, so trifft die Legal Software
                            UG (haftungsbeschränkt) unter Berücksichtigung der verfügbaren
                            Technologie und der Implementierungskosten angemessene Maßnahmen,
                            auch technischer Art, um andere für die Datenverarbeitung
                            Verantwortliche, welche die veröffentlichten personenbezogenen
                            Daten verarbeiten, darüber in Kenntnis zu setzen, dass die
                            betroffene Person von diesen anderen für die Datenverarbeitung
                            Verantwortlichen die Löschung sämtlicher Links zu diesen
                            personenbezogenen Daten oder von Kopien oder Replikationen dieser
                            personenbezogenen Daten verlangt hat, soweit die Verarbeitung nicht
                            erforderlich ist. Der Mitarbeiter der Legal Software UG
                            (haftungsbeschränkt) wird im Einzelfall das Notwendige veranlassen.
                        </p>
                    </div>
                </div>
                <div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    e)    Recht auf Einschränkung der Verarbeitung
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Jede von der Verarbeitung personenbezogener Daten betroffene Person
                            hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte
                            Recht, von dem Verantwortlichen die Einschränkung der Verarbeitung
                            zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:
                        </p>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    Die Richtigkeit der personenbezogenen Daten wird von der
                                    betroffenen Person bestritten, und zwar für eine Dauer, die
                                    es dem Verantwortlichen ermöglicht, die Richtigkeit der
                                    personenbezogenen Daten zu überprüfen.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Die Verarbeitung ist unrechtmäßig, die betroffene Person
                                    lehnt die Löschung der personenbezogenen Daten ab und
                                    verlangt stattdessen die Einschränkung der Nutzung der
                                    personenbezogenen Daten.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Der Verantwortliche benötigt die personenbezogenen Daten
                                    für die Zwecke der Verarbeitung nicht länger, die
                                    betroffene Person benötigt sie jedoch zur Geltendmachung,
                                    Ausübung oder Verteidigung von Rechtsansprüchen.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    Die betroffene Person hat Widerspruch gegen die
                                    Verarbeitung gem. Art. 21 Abs. 1 DS-GVO eingelegt und es
                                    steht noch nicht fest, ob die berechtigten Gründe des
                                    Verantwortlichen gegenüber denen der betroffenen Person
                                    überwiegen.
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Sofern eine der oben genannten Voraussetzungen gegeben ist und eine
                            betroffene Person die Einschränkung von personenbezogenen Daten,
                            die bei der Legal Software UG (haftungsbeschränkt) gespeichert
                            sind, verlangen möchte, kann sie sich hierzu jederzeit an einen
                            Mitarbeiter des für die Verarbeitung Verantwortlichen wenden. Der
                            Mitarbeiter der Legal Software UG (haftungsbeschränkt) wird die
                            Einschränkung der Verarbeitung veranlassen.
                        </p>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    f)     Recht auf Datenübertragbarkeit
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Jede von der Verarbeitung personenbezogener Daten betroffene Person
                            hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte
                            Recht, die sie betreffenden personenbezogenen Daten, welche durch
                            die betroffene Person einem Verantwortlichen bereitgestellt wurden,
                            in einem strukturierten, gängigen und maschinenlesbaren Format zu
                            erhalten. Sie hat außerdem das Recht, diese Daten einem anderen
                            Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem
                            die personenbezogenen Daten bereitgestellt wurden, zu übermitteln,
                            sofern die Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1
                            Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO oder auf
                            einem Vertrag gemäß Art. 6 Abs. 1 Buchstabe b DS-GVO beruht und die
                            Verarbeitung mithilfe automatisierter Verfahren erfolgt, sofern die
                            Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich
                            ist, die im öffentlichen Interesse liegt oder in Ausübung
                            öffentlicher Gewalt erfolgt, welche dem Verantwortlichen übertragen
                            wurde.
                        </p>
                    </div>
                    <div>
                        <p>
                            Ferner hat die betroffene Person bei der Ausübung ihres Rechts auf
                            Datenübertragbarkeit gemäß Art. 20 Abs. 1 DS-GVO das Recht, zu
                            erwirken, dass die personenbezogenen Daten direkt von einem
                            Verantwortlichen an einen anderen Verantwortlichen übermittelt
                            werden, soweit dies technisch machbar ist und sofern hiervon nicht
                            die Rechte und Freiheiten anderer Personen beeinträchtigt werden.
                        </p>
                    </div>
                </div>
                <div>
                    <div>
                        <p>
                            Zur Geltendmachung des Rechts auf Datenübertragbarkeit kann sich
                            die betroffene Person jederzeit an einen Mitarbeiter der Legal
                            Software UG (haftungsbeschränkt) wenden.
                        </p>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    g)    Recht auf Widerspruch
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Jede von der Verarbeitung personenbezogener Daten betroffene Person
                            hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte
                            Recht, aus Gründen, die sich aus ihrer besonderen Situation
                            ergeben, jederzeit gegen die Verarbeitung sie betreffender
                            personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 Buchstaben
                            e oder f DS-GVO erfolgt, Widerspruch einzulegen. Dies gilt auch für
                            ein auf diese Bestimmungen gestütztes Profiling.
                        </p>
                    </div>
                    <div>
                        <p>
                            Die Legal Software UG (haftungsbeschränkt) verarbeitet die
                            personenbezogenen Daten im Falle des Widerspruchs nicht mehr, es
                            sei denn, wir können zwingende schutzwürdige Gründe für die
                            Verarbeitung nachweisen, die den Interessen, Rechten und Freiheiten
                            der betroffenen Person überwiegen, oder die Verarbeitung dient der
                            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
                        </p>
                    </div>
                    <div>
                        <p>
                            Verarbeitet die Legal Software UG (haftungsbeschränkt)
                            personenbezogene Daten, um Direktwerbung zu betreiben, so hat die
                            betroffene Person das Recht, jederzeit Widerspruch gegen die
                            Verarbeitung der personenbezogenen Daten zum Zwecke derartiger
                            Werbung einzulegen. Dies gilt auch für das Profiling, soweit es mit
                            solcher Direktwerbung in Verbindung steht. Widerspricht die
                            betroffene Person gegenüber der Legal Software UG
                            (haftungsbeschränkt) der Verarbeitung für Zwecke der Direktwerbung,
                            so wird die Legal Software UG (haftungsbeschränkt) die
                            personenbezogenen Daten nicht mehr für diese Zwecke verarbeiten.
                        </p>
                    </div>
                </div>
                <div>
                    <div>
                        <p>
                            Zudem hat die betroffene Person das Recht, aus Gründen, die sich
                            aus ihrer besonderen Situation ergeben, gegen die sie betreffende
                            Verarbeitung personenbezogener Daten, die bei der Legal Software UG
                            (haftungsbeschränkt) zu wissenschaftlichen oder historischen
                            Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89 Abs.
                            1 DS-GVO erfolgen, Widerspruch einzulegen, es sei denn, eine solche
                            Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse
                            liegenden Aufgabe erforderlich.
                        </p>
                    </div>
                    <div>
                        <p>
                            Zur Ausübung des Rechts auf Widerspruch kann sich die betroffene
                            Person direkt an jeden Mitarbeiter der Legal Software UG
                            (haftungsbeschränkt) oder einen anderen Mitarbeiter wenden. Der
                            betroffenen Person steht es ferner frei, im Zusammenhang mit der
                            Nutzung von Diensten der Informationsgesellschaft, ungeachtet der
                            Richtlinie 2002/58/EG, ihr Widerspruchsrecht mittels
                            automatisierter Verfahren auszuüben, bei denen technische
                            Spezifikationen verwendet werden.
                        </p>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    h)    Automatisierte Entscheidungen im Einzelfall
                                    einschließlich Profiling
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Jede von der Verarbeitung personenbezogener Daten betroffene Person
                            hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte
                            Recht, nicht einer ausschließlich auf einer automatisierten
                            Verarbeitung — einschließlich Profiling — beruhenden Entscheidung
                            unterworfen zu werden, die ihr gegenüber rechtliche Wirkung
                            entfaltet oder sie in ähnlicher Weise erheblich beeinträchtigt,
                            sofern die Entscheidung (1) nicht für den Abschluss oder die
                            Erfüllung eines Vertrags zwischen der betroffenen Person und dem
                            Verantwortlichen erforderlich ist, oder (2) aufgrund von
                            Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der
                            Verantwortliche unterliegt, zulässig ist und diese
                            Rechtsvorschriften angemessene Maßnahmen zur Wahrung der Rechte und
                            Freiheiten sowie der berechtigten Interessen der betroffenen Person
                            enthalten oder (3) mit ausdrücklicher Einwilligung der betroffenen
                            Person erfolgt.
                        </p>
                    </div>
                    <div>
                        <p>
                            Ist die Entscheidung (1) für den Abschluss oder die Erfüllung eines
                            Vertrags zwischen der betroffenen Person und dem Verantwortlichen
                            erforderlich oder (2) erfolgt sie mit ausdrücklicher Einwilligung
                            der betroffenen Person, trifft die Legal Software UG
                            (haftungsbeschränkt) angemessene Maßnahmen, um die Rechte und
                            Freiheiten sowie die berechtigten Interessen der betroffenen Person
                            zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens
                            einer Person seitens des Verantwortlichen, auf Darlegung des
                            eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
                        </p>
                    </div>
                </div>
                <div>
                    <div>
                        <p>
                            Möchte die betroffene Person Rechte mit Bezug auf automatisierte
                            Entscheidungen geltend machen, kann sie sich hierzu jederzeit an
                            einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
                        </p>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <p>
                                    i)      Recht auf Widerruf einer datenschutzrechtlichen
                                    Einwilligung
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p>
                            Jede von der Verarbeitung personenbezogener Daten betroffene Person
                            hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte
                            Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten
                            jederzeit zu widerrufen.
                        </p>
                    </div>
                    <div>
                        <p>
                            Möchte die betroffene Person ihr Recht auf Widerruf einer
                            Einwilligung geltend machen, kann sie sich hierzu jederzeit an
                            einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
                        </p>
                    </div>
                    <div>
                        <p>
                            12. Datenschutz bei Bewerbungen und im Bewerbungsverfahren
                        </p>
                    </div>
                </div>
                <div>
                    <p>
                        Der für die Verarbeitung Verantwortliche erhebt und verarbeitet die
                        personenbezogenen Daten von Bewerbern zum Zwecke der Abwicklung des
                        Bewerbungsverfahrens. Die Verarbeitung kann auch auf elektronischem
                        Wege erfolgen. Dies ist insbesondere dann der Fall, wenn ein Bewerber
                        entsprechende Bewerbungsunterlagen auf dem elektronischen Wege,
                        beispielsweise per E-Mail oder über ein auf der Internetseite
                        befindliches Webformular, an den für die Verarbeitung Verantwortlichen
                        übermittelt. Schließt der für die Verarbeitung Verantwortliche einen
                        Anstellungsvertrag mit einem Bewerber, werden die übermittelten Daten
                        zum Zwecke der Abwicklung des Beschäftigungsverhältnisses unter
                        Beachtung der gesetzlichen Vorschriften gespeichert. Wird von dem für
                        die Verarbeitung Verantwortlichen kein Anstellungsvertrag mit dem
                        Bewerber geschlossen, so werden die Bewerbungsunterlagen zwei Monate
                        nach Bekanntgabe der Absageentscheidung automatisch gelöscht, sofern
                        einer Löschung keine sonstigen berechtigten Interessen des für die
                        Verarbeitung Verantwortlichen entgegenstehen. Sonstiges berechtigtes
                        Interesse in diesem Sinne ist beispielsweise eine Beweispflicht in
                        einem Verfahren nach dem Allgemeinen Gleichbehandlungsgesetz (AGG).
                    </p>
                </div>
                <div>
                    <p>
                        13. Datenschutzbestimmungen zu Einsatz und Verwendung von LinkedIn
                    </p>
                </div>
                <div>
                    <p>
                        Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite
                        Komponenten der LinkedIn Corporation integriert. LinkedIn ist ein
                        Internetbasiertes soziales Netzwerk, das eine Konnektierung der Nutzer
                        mit bestehenden Geschäftskontakten sowie das Knüpfen von neuen
                        Businesskontakten ermöglicht. Über 400 Millionen registrierte Personen
                        nutzen LinkedIn in mehr als 200 Ländern. Damit ist LinkedIn derzeit die
                        größte Plattform für Businesskontakte und eine der meistbesuchten
                        Internetseiten der Welt.
                    </p>
                </div>
                <div>
                    <p>
                        Betreibergesellschaft von LinkedIn ist die LinkedIn Corporation, 2029
                        Stierlin Court Mountain View, CA 94043, USA. Für
                        Datenschutzangelegenheiten außerhalb der USA ist LinkedIn Ireland,
                        Privacy Policy Issues, Wilton Plaza, Wilton Place, Dublin 2, Ireland,
                        zuständig.
                    </p>
                </div>
                <div>
                    <p>
                        Bei jedem einzelnen Abruf unserer Internetseite, die mit einer
                        LinkedIn-Komponente (LinkedIn-Plug-In) ausgestattet ist, veranlasst
                        diese Komponente, dass der von der betroffenen Person verwendete
                        Browser eine entsprechende Darstellung der Komponente von LinkedIn
                        herunterlädt. Weitere Informationen zu den LinkedIn-Plug-Ins können
                        unter https://developer.linkedin.com/plugins abgerufen werden. Im
                        Rahmen dieses technischen Verfahrens erhält LinkedIn Kenntnis darüber,
                        welche konkrete Unterseite unserer Internetseite durch die betroffene
                        Person besucht wird.
                    </p>
                </div>
                <div>
                    <p>
                        Sofern die betroffene Person gleichzeitig bei LinkedIn eingeloggt ist,
                        erkennt LinkedIn mit jedem Aufruf unserer Internetseite durch die
                        betroffene Person und während der gesamten Dauer des jeweiligen
                        Aufenthaltes auf unserer Internetseite, welche konkrete Unterseite
                        unserer Internetseite die betroffene Person besucht. Diese
                        Informationen werden durch die LinkedIn-Komponente gesammelt und durch
                        LinkedIn dem jeweiligen LinkedIn-Account der betroffenen Person
                        zugeordnet. Betätigt die betroffene Person einen auf unserer
                        Internetseite integrierten LinkedIn-Button, ordnet LinkedIn diese
                        Information dem persönlichen LinkedIn-Benutzerkonto der betroffenen
                        Person zu und speichert diese personenbezogenen Daten.
                    </p>
                </div>
                <div>
                    <p>
                        LinkedIn erhält über die LinkedIn-Komponente immer dann eine
                        Information darüber, dass die betroffene Person unsere Internetseite
                        besucht hat, wenn die betroffene Person zum Zeitpunkt des Aufrufes
                        unserer Internetseite gleichzeitig bei LinkedIn eingeloggt ist; dies
                        findet unabhängig davon statt, ob die betroffene Person die
                        LinkedIn-Komponente anklickt oder nicht. Ist eine derartige
                        Übermittlung dieser Informationen an LinkedIn von der betroffenen
                        Person nicht gewollt, kann diese die Übermittlung dadurch verhindern,
                        dass sie sich vor einem Aufruf unserer Internetseite aus ihrem
                        LinkedIn-Account ausloggt.
                    </p>
                </div>
                <div>
                    <p>
                        LinkedIn bietet unter https://www.linkedin.com/psettings/guest-controls
                        die Möglichkeit, E-Mail-Nachrichten, SMS-Nachrichten und zielgerichtete
                        Anzeigen abzubestellen sowie Anzeigen-Einstellungen zu verwalten.
                        LinkedIn nutzt ferner Partner wie Quantcast, Google Analytics, BlueKai,
                        DoubleClick, Nielsen, Comscore, Eloqua und Lotame, die Cookies setzen
                        können. Solche Cookies können unter
                        https://www.linkedin.com/legal/cookie-policy abgelehnt werden. Die
                        geltenden Datenschutzbestimmungen von LinkedIn sind unter
                        https://www.linkedin.com/legal/privacy-policy abrufbar. Die
                        Cookie-Richtlinie von LinkedIn ist unter
                        https://www.linkedin.com/legal/cookie-policy abrufbar.
                    </p>
                </div>
                <div>
                    <p>
                        14. Datenschutzbestimmungen zu Einsatz und Verwendung von Facebook
                    </p>
                </div>
                <div>
                    <p>
                        Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite
                        Komponenten des Unternehmens Facebook integriert. Facebook ist ein
                        soziales Netzwerk.
                    </p>
                </div>
                <div>
                    <p>
                        Ein soziales Netzwerk ist ein im Internet betriebener sozialer
                        Treffpunkt, eine Online-Gemeinschaft, die es den Nutzern in der Regel
                        ermöglicht, untereinander zu kommunizieren und im virtuellen Raum zu
                        interagieren. Ein soziales Netzwerk kann als Plattform zum Austausch
                        von Meinungen und Erfahrungen dienen oder ermöglicht es der
                        Internetgemeinschaft, persönliche oder unternehmensbezogene
                        Informationen bereitzustellen. Facebook ermöglicht den Nutzern des
                        sozialen Netzwerkes unter anderem die Erstellung von privaten Profilen,
                        den Upload von Fotos und eine Vernetzung über Freundschaftsanfragen.
                    </p>
                </div>
                <div>
                    <p>
                        Betreibergesellschaft von Facebook ist die Facebook, Inc., 1 Hacker
                        Way, Menlo Park, CA 94025, USA. Für die Verarbeitung personenbezogener
                        Daten Verantwortlicher ist, wenn eine betroffene Person außerhalb der
                        USA oder Kanada lebt, die Facebook Ireland Ltd., 4 Grand Canal Square,
                        Grand Canal Harbour, Dublin 2, Ireland.
                    </p>
                </div>
                <div>
                    <p>
                        Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die
                        durch den für die Verarbeitung Verantwortlichen betrieben wird und auf
                        welcher eine Facebook-Komponente (Facebook-Plug-In) integriert wurde,
                        wird der Internetbrowser auf dem informationstechnologischen System der
                        betroffenen Person automatisch durch die jeweilige Facebook-Komponente
                        veranlasst, eine Darstellung der entsprechenden Facebook-Komponente von
                        Facebook herunterzuladen. Eine Gesamtübersicht über alle
                        Facebook-Plug-Ins kann unter
                        https://developers.facebook.com/docs/plugins/?locale=de_DE abgerufen
                        werden. Im Rahmen dieses technischen Verfahrens erhält Facebook
                        Kenntnis darüber, welche konkrete Unterseite unserer Internetseite
                        durch die betroffene Person besucht wird.
                    </p>
                </div>
                <div>
                    <p>
                        Sofern die betroffene Person gleichzeitig bei Facebook eingeloggt ist,
                        erkennt Facebook mit jedem Aufruf unserer Internetseite durch die
                        betroffene Person und während der gesamten Dauer des jeweiligen
                        Aufenthaltes auf unserer Internetseite, welche konkrete Unterseite
                        unserer Internetseite die betroffene Person besucht. Diese
                        Informationen werden durch die Facebook-Komponente gesammelt und durch
                        Facebook dem jeweiligen Facebook-Account der betroffenen Person
                        zugeordnet. Betätigt die betroffene Person einen der auf unserer
                        Internetseite integrierten Facebook-Buttons, beispielsweise den
                        „Gefällt mir“-Button, oder gibt die betroffene Person einen Kommentar
                        ab, ordnet Facebook diese Information dem persönlichen
                        Facebook-Benutzerkonto der betroffenen Person zu und speichert diese
                        personenbezogenen Daten.
                    </p>
                </div>
                <div>
                    <p>
                        Facebook erhält über die Facebook-Komponente immer dann eine
                        Information darüber, dass die betroffene Person unsere Internetseite
                        besucht hat, wenn die betroffene Person zum Zeitpunkt des Aufrufs
                        unserer Internetseite gleichzeitig bei Facebook eingeloggt ist; dies
                        findet unabhängig davon statt, ob die betroffene Person die
                        Facebook-Komponente anklickt oder nicht. Ist eine derartige
                        Übermittlung dieser Informationen an Facebook von der betroffenen
                        Person nicht gewollt, kann diese die Übermittlung dadurch verhindern,
                        dass sie sich vor einem Aufruf unserer Internetseite aus ihrem
                        Facebook-Account ausloggt.
                    </p>
                </div>
                <div>
                    <p>
                        Die von Facebook veröffentlichte Datenrichtlinie, die unter
                        https://de-de.facebook.com/about/privacy/ abrufbar ist, gibt Aufschluss
                        über die Erhebung, Verarbeitung und Nutzung personenbezogener Daten
                        durch Facebook. Ferner wird dort erläutert, welche
                        Einstellungsmöglichkeiten Facebook zum Schutz der Privatsphäre der
                        betroffenen Person bietet. Zudem sind unterschiedliche Applikationen
                        erhältlich, die es ermöglichen, eine Datenübermittlung an Facebook zu
                        unterdrücken. Solche Applikationen können durch die betroffene Person
                        genutzt werden, um eine Datenübermittlung an Facebook zu unterdrücken.
                    </p>
                </div>
                <div>
                    <p>
                        15. Datenschutzbestimmungen zu Einsatz und Verwendung von Hotjar (mit
                        Anonymisierungsfunktion)
                    </p>
                </div>
                <div>
                    <p>
                        Wir verwenden auf unserer Website Hotjar der Firma Hotjar Limited
                        (Level 2, St Julian’s Business Centre, 3, Elia Zammit Street, St
                        Julian’s STJ 1000, Malta), um Besucherdaten statistisch auszuwerten.
                        Hotjar ist ein Dienst, der das Verhalten und das Feedback von Ihnen als
                        Nutzer auf unserer Webseite durch eine Kombination von Analyse- und
                        Feedback-Tools analysiert. Wir erhalten von Hotjar Berichte und
                        visuelle Darstellungen, die uns zeigen wo und wie Sie sich auf unserer
                        Seite “bewegen”. Personenbezogenen Daten werden automatisch
                        anonymisiert und erreichen niemals die Server von Hotjar. Das heißt Sie
                        werden als Webseitenbenutzer nicht persönlich identifiziert und wir
                        lernen dennoch vieles über Ihr Userverhalten.
                    </p>
                </div>
                <div>
                    <p>
                        Wie im oberen Abschnitt bereits erwähnt, hilft uns Hotjar das Verhalten
                        unserer Seitenbesucher zu analysieren. Zu diesen Tools, die Hotjar
                        anbietet,  gehören Heatmaps, Conversion Funnels, Visitor Recording,
                        Incoming Feedback, Feedback Polls und Surveys (mehr Informationen
                        darüber erhalten Sie unter
                        <a
                            href="https://www.hotjar.com/?tid=311292617/"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            https://www.hotjar.com/
                        </a>
                        ). Damit hilft uns Hotjar, Ihnen eine bessere Nutzererfahrung und ein
                        besseres Service anzubieten. Es bietet somit einerseits eine gute
                        Analyse über das Onlineverhalten, andererseits erhalten wir auch ein
                        gutes Feedback über die Qualität unserer Webseite. Denn neben all den
                        analysetechnischen Aspekten wollen wir natürlich auch einfach Ihre
                        Meinung über unsere Webseite wissen. Und mit dem Feedbacktool ist genau
                        das möglich.
                    </p>
                </div>
                <div>
                    <p>
                        Warum verwenden wir Hotjar auf unserer Website?
                    </p>
                </div>
                <div>
                    <p>
                        In den letzten Jahren nahm die Bedeutung von User Experience (also
                        Benutzererfahrung) auf Webseiten stark zu. Und das auch aus gutem
                        Grund. Eine Webseite soll so aufgebaut sein, dass Sie sich als Besucher
                        wohl fühlen und sich einfach zurechtfinden. Wir können dank der
                        Analyse-Tools und des Feedback-Tools von Hotjar unsere Webseite und
                        unser Angebot attraktiver gestalten.
                    </p>
                </div>
                <div>
                    <p>
                        Zudem speichern auch Cookies Daten, die auf Ihrem Computer (meist in
                        Ihrem Browser) platziert werden. Darin werden keine personenbezogenen
                        Daten gesammelt.
                    </p>
                </div>
                <div>
                    <p>
                        Auf die gespeicherten Informationen haben nur eine begrenzte Anzahl an
                        Personen (Mitarbeiter von Hotjar) Zugriff. Die Hotjar-Server sind durch
                        Firewalls und IP-Beschränkungen (Zugriff nur genehmigter IP-Adressen)
                        geschützt. Firewalls sind Sicherheitssysteme, die Computer vor
                        unerwünschten Netzwerkzugriffen schützen. Sie sollen als Barriere
                        zwischen dem sicheren internen Netzwerk von Hotjar und dem Internet
                        dienen. Weiters verwendet Hotjar für Ihre Dienste auch
                        Drittunternehmen, wie etwa Google Analytics oder Optimizely. Diese
                        Firmen können auch Informationen, die Ihr Browser an unsere Webseite
                        sendet, speichern.
                    </p>
                </div>
                <div>
                    <p>
                        16. Datenschutzbestimmungen zu Einsatz und Verwendung von Google
                        Analytics (mit Anonymisierungsfunktion)
                    </p>
                </div>
                <div>
                    <p>
                        Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite
                        die Komponente Google Analytics (mit Anonymisierungsfunktion)
                        integriert. Google Analytics ist ein Web-Analyse-Dienst. Web-Analyse
                        ist die Erhebung, Sammlung und Auswertung von Daten über das Verhalten
                        von Besuchern von Internetseiten. Ein Web-Analyse-Dienst erfasst unter
                        anderem Daten darüber, von welcher Internetseite eine betroffene Person
                        auf eine Internetseite gekommen ist (sogenannte Referrer), auf welche
                        Unterseiten der Internetseite zugegriffen oder wie oft und für welche
                        Verweildauer eine Unterseite betrachtet wurde. Eine Web-Analyse wird
                        überwiegend zur Optimierung einer Internetseite und zur
                        Kosten-Nutzen-Analyse von Internetwerbung eingesetzt.
                    </p>
                </div>
                <div>
                    <p>
                        Betreibergesellschaft der Google-Analytics-Komponente ist die Google
                        Ireland Limited, Gordon House, Barrow Street, Dublin, D04 E5W5,
                        Ireland.
                    </p>
                </div>
                <div>
                    <p>
                        Der für die Verarbeitung Verantwortliche verwendet für die Web-Analyse
                        über Google Analytics den Zusatz "_gat._anonymizeIp". Mittels dieses
                        Zusatzes wird die IP-Adresse des Internetanschlusses der betroffenen
                        Person von Google gekürzt und anonymisiert, wenn der Zugriff auf unsere
                        Internetseiten aus einem Mitgliedstaat der Europäischen Union oder aus
                        einem anderen Vertragsstaat des Abkommens über den Europäischen
                        Wirtschaftsraum erfolgt.
                    </p>
                </div>
                <div>
                    <p>
                        Der Zweck der Google-Analytics-Komponente ist die Analyse der
                        Besucherströme auf unserer Internetseite. Google nutzt die gewonnenen
                        Daten und Informationen unter anderem dazu, die Nutzung unserer
                        Internetseite auszuwerten, um für uns Online-Reports, welche die
                        Aktivitäten auf unseren Internetseiten aufzeigen, zusammenzustellen,
                        und um weitere mit der Nutzung unserer Internetseite in Verbindung
                        stehende Dienstleistungen zu erbringen.
                    </p>
                </div>
                <div>
                    <p>
                        Google Analytics setzt ein Cookie auf dem informationstechnologischen
                        System der betroffenen Person. Mit Setzung des Cookies wird Google eine
                        Analyse der Benutzung unserer Internetseite ermöglicht. Durch jeden
                        Aufruf einer der Einzelseiten dieser Internetseite, die durch den für
                        die Verarbeitung Verantwortlichen betrieben wird und auf welcher eine
                        Google-Analytics-Komponente integriert wurde, wird der Internetbrowser
                        auf dem informationstechnologischen System der betroffenen Person
                        automatisch durch die jeweilige Google-Analytics-Komponente veranlasst,
                        Daten zum Zwecke der Online-Analyse an Google zu übermitteln. Im Rahmen
                        dieses technischen Verfahrens erhält Google Kenntnis über
                        personenbezogene Daten, wie der IP-Adresse der betroffenen Person, die
                        Google unter anderem dazu dienen, die Herkunft der Besucher und Klicks
                        nachzuvollziehen und in der Folge Provisionsabrechnungen zu
                        ermöglichen.
                    </p>
                </div>
                <div>
                    <p>
                        Mittels des Cookies werden personenbezogene Informationen,
                        beispielsweise die Zugriffszeit, der Ort, von welchem ein Zugriff
                        ausging und die Häufigkeit der Besuche unserer Internetseite durch die
                        betroffene Person, gespeichert. Bei jedem Besuch unserer Internetseiten
                        werden diese personenbezogenen Daten, einschließlich der IP-Adresse des
                        von der betroffenen Person genutzten Internetanschlusses, an Google in
                        den Vereinigten Staaten von Amerika übertragen. Diese personenbezogenen
                        Daten werden durch Google in den Vereinigten Staaten von Amerika
                        gespeichert. Google gibt diese über das technische Verfahren erhobenen
                        personenbezogenen Daten unter Umständen an Dritte weiter.
                    </p>
                </div>
                <div>
                    <p>
                        Die betroffene Person kann die Setzung von Cookies durch unsere
                        Internetseite, wie oben bereits dargestellt, jederzeit mittels einer
                        entsprechenden Einstellung des genutzten Internetbrowsers verhindern
                        und damit der Setzung von Cookies dauerhaft widersprechen. Eine solche
                        Einstellung des genutzten Internetbrowsers würde auch verhindern, dass
                        Google ein Cookie auf dem informationstechnologischen System der
                        betroffenen Person setzt. Zudem kann ein von Google Analytics bereits
                        gesetzter Cookie jederzeit über den Internetbrowser oder andere
                        Softwareprogramme gelöscht werden.
                    </p>
                </div>
                <div>
                    <p>
                        Ferner besteht für die betroffene Person die Möglichkeit, einer
                        Erfassung der durch Google Analytics erzeugten, auf eine Nutzung dieser
                        Internetseite bezogenen Daten sowie der Verarbeitung dieser Daten durch
                        Google zu widersprechen und eine solche zu verhindern. Hierzu muss die
                        betroffene Person ein Browser-Add-On unter dem Link
                        https://tools.google.com/dlpage/gaoptout herunterladen und
                        installieren. Dieses Browser-Add-On teilt Google Analytics über
                        JavaScript mit, dass keine Daten und Informationen zu den Besuchen von
                        Internetseiten an Google Analytics übermittelt werden dürfen. Die
                        Installation des Browser-Add-Ons wird von Google als Widerspruch
                        gewertet. Wird das informationstechnologische System der betroffenen
                        Person zu einem späteren Zeitpunkt gelöscht, formatiert oder neu
                        installiert, muss durch die betroffene Person eine erneute Installation
                        des Browser-Add-Ons erfolgen, um Google Analytics zu deaktivieren.
                        Sofern das Browser-Add-On durch die betroffene Person oder einer
                        anderen Person, die ihrem Machtbereich zuzurechnen ist, deinstalliert
                        oder deaktiviert wird, besteht die Möglichkeit der Neuinstallation oder
                        der erneuten Aktivierung des Browser-Add-Ons.
                    </p>
                </div>
                <div>
                    <p>
                        Weitere Informationen und die geltenden Datenschutzbestimmungen von
                        Google können unter https://www.google.de/intl/de/policies/privacy/ und
                        unter http://www.google.com/analytics/terms/de.html abgerufen werden.
                        Google Analytics wird unter diesem Link
                        https://www.google.com/intl/de_de/analytics/ genauer erläutert.
                    </p>
                </div>
                <div>
                    <p>
                        17. Rechtsgrundlage der Verarbeitung
                    </p>
                </div>
                <div>
                    <p>
                        Art. 6 I lit. a DS-GVO dient unserem Unternehmen als Rechtsgrundlage
                        für Verarbeitungsvorgänge, bei denen wir eine Einwilligung für einen
                        bestimmten Verarbeitungszweck einholen. Ist die Verarbeitung
                        personenbezogener Daten zur Erfüllung eines Vertrags, dessen
                        Vertragspartei die betroffene Person ist, erforderlich, wie dies
                        beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für eine
                        Lieferung von Waren oder die Erbringung einer sonstigen Leistung oder
                        Gegenleistung notwendig sind, so beruht die Verarbeitung auf Art. 6 I
                        lit. b DS-GVO. Gleiches gilt für solche Verarbeitungsvorgänge die zur
                        Durchführung vorvertraglicher Maßnahmen erforderlich sind, etwa in
                        Fällen von Anfragen zu unseren Produkten oder Leistungen. Unterliegt
                        unser Unternehmen einer rechtlichen Verpflichtung durch welche eine
                        Verarbeitung von personenbezogenen Daten erforderlich wird, wie
                        beispielsweise zur Erfüllung steuerlicher Pflichten, so basiert die
                        Verarbeitung auf Art. 6 I lit. c DS-GVO. In seltenen Fällen könnte die
                        Verarbeitung von personenbezogenen Daten erforderlich werden, um
                        lebenswichtige Interessen der betroffenen Person oder einer anderen
                        natürlichen Person zu schützen. Dies wäre beispielsweise der Fall, wenn
                        ein Besucher in unserem Betrieb verletzt werden würde und daraufhin
                        sein Name, sein Alter, seine Krankenkassendaten oder sonstige
                        lebenswichtige Informationen an einen Arzt, ein Krankenhaus oder
                        sonstige Dritte weitergegeben werden müssten. Dann würde die
                        Verarbeitung auf Art. 6 I lit. d DS-GVO beruhen. Letztlich könnten
                        Verarbeitungsvorgänge auf Art. 6 I lit. f DS-GVO beruhen. Auf dieser
                        Rechtsgrundlage basieren Verarbeitungsvorgänge, die von keiner der
                        vorgenannten Rechtsgrundlagen erfasst werden, wenn die Verarbeitung zur
                        Wahrung eines berechtigten Interesses unseres Unternehmens oder eines
                        Dritten erforderlich ist, sofern die Interessen, Grundrechte und
                        Grundfreiheiten des Betroffenen nicht überwiegen. Solche
                        Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil sie
                        durch den Europäischen Gesetzgeber besonders erwähnt wurden. Er vertrat
                        insoweit die Auffassung, dass ein berechtigtes Interesse anzunehmen
                        sein könnte, wenn die betroffene Person ein Kunde des Verantwortlichen
                        ist (Erwägungsgrund 47 Satz 2 DS-GVO).
                    </p>
                </div>
                <div>
                    <p>
                        18. Berechtigte Interessen an der Verarbeitung, die von dem
                        Verantwortlichen oder einem Dritten verfolgt werden
                    </p>
                </div>
                <div>
                    <p>
                        Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I lit. f
                        DS-GVO ist unser berechtigtes Interesse die Durchführung unserer
                        Geschäftstätigkeit zugunsten des Wohlergehens all unserer Mitarbeiter
                        und unserer Anteilseigner.
                    </p>
                </div>
                <div>
                    <p>
                        19. Dauer, für die die personenbezogenen Daten gespeichert werden
                    </p>
                </div>
                <div>
                    <p>
                        Das Kriterium für die Dauer der Speicherung von personenbezogenen Daten
                        ist die jeweilige gesetzliche Aufbewahrungsfrist. Nach Ablauf der Frist
                        werden die entsprechenden Daten routinemäßig gelöscht, sofern sie nicht
                        mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich sind.
                    </p>
                </div>
                <div>
                    <p>
                        20. Gesetzliche oder vertragliche Vorschriften zur Bereitstellung der
                        personenbezogenen Daten; Erforderlichkeit für den Vertragsabschluss;
                        Verpflichtung der betroffenen Person, die personenbezogenen Daten
                        bereitzustellen; mögliche Folgen der Nichtbereitstellung
                    </p>
                </div>
                <div>
                    <p>
                        Wir klären Sie darüber auf, dass die Bereitstellung personenbezogener
                        Daten zum Teil gesetzlich vorgeschrieben ist (z.B. Steuervorschriften)
                        oder sich auch aus vertraglichen Regelungen (z.B. Angaben zum
                        Vertragspartner) ergeben kann. Mitunter kann es zu einem
                        Vertragsschluss erforderlich sein, dass eine betroffene Person uns
                        personenbezogene Daten zur Verfügung stellt, die in der Folge durch uns
                        verarbeitet werden müssen. Die betroffene Person ist beispielsweise
                        verpflichtet uns personenbezogene Daten bereitzustellen, wenn unser
                        Unternehmen mit ihr einen Vertrag abschließt. Eine Nichtbereitstellung
                        der personenbezogenen Daten hätte zur Folge, dass der Vertrag mit dem
                        Betroffenen nicht geschlossen werden könnte. Vor einer Bereitstellung
                        personenbezogener Daten durch den Betroffenen muss sich der Betroffene
                        an einen unserer Mitarbeiter wenden. Unser Mitarbeiter klärt den
                        Betroffenen einzelfallbezogen darüber auf, ob die Bereitstellung der
                        personenbezogenen Daten gesetzlich oder vertraglich vorgeschrieben oder
                        für den Vertragsabschluss erforderlich ist, ob eine Verpflichtung
                        besteht, die personenbezogenen Daten bereitzustellen, und welche Folgen
                        die Nichtbereitstellung der personenbezogenen Daten hätte.
                    </p>
                </div>
                <div>
                    <p>
                        21. Bestehen einer automatisierten Entscheidungsfindung
                    </p>
                </div>
                <div>
                    <p>
                        Als verantwortungsbewusstes Unternehmen verzichten wir auf eine
                        automatische Entscheidungsfindung oder ein Profiling.
                    </p>
                </div>
                <div>
                    <p>
                        Diese Datenschutzerklärung wurde durch den
                        Datenschutzerklärungs-Generator der DGD Deutsche Gesellschaft für
                        Datenschutz GmbH, die als
                        <a
                            href="https://dg-datenschutz.de/datenschutz-dienstleistungen/externer-datenschutzbeauftragter/"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            externer Datenschutzbeauftragter
                        </a>
                        tätig ist, in Kooperation mit dem
                        <a
                            href="https://www.wbs-law.de/"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            Datenschutz Anwalt Christian Solmecke
                        </a>
                        erstellt. Der Textbaustein zur Verwendung von Hotjar (15.) wurde mit dem
                        <a href="https://www.adsimple.de/datenschutz-generator/">
                            Datenschutz Generator von AdSimple
                        </a>
                        erstellt.
                    </p>
                </div>
            </div>
            </div>
        </TextPage>
)
}

export default DatenschutzPage